
import { defineComponent, ref, reactive, watch, inject, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import api from "@/api/axios";
import useFootPrint from "@/hooks/useFootPrint";
import SlotFooter from "@/components/common/SlotFooter.vue";
import Loading from "@/components/UI/Loading";
import navigatorType from "@/utils/browser";

export default defineComponent({
  name: "productdetail",
  components: {
    SlotFooter,
    Loading,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const id = ref(route.params.id);
    const classId = ref(route.params.classId);
    const isFav = ref(false);
    const productsVO = ref(null);
    const list = ref([]);
    const secListComp = ref([]);
    const amclass = ref(null);
    const comp = ref({});
    const RelatedProIdList = ref([]);
    const { setFootItem } = useFootPrint();
    const listCompLen = ref(0);
    function getData(id: any) {
      productsVO.value = null;
      api
        .get("/M/Product/ProductDetail/" + id)
        .then((res): void => {
          const data = res.data.obj;
          productsVO.value = data.productsVO;
          document.title = data.productsVO.cnname + "-北纳生物";
          list.value = data.listComp;
          comp.value = data.comp;
          isFav.value = data.isFav;
          amclass.value = data.amclass;
          RelatedProIdList.value = data.RelatedProIdList;
          secListComp.value = data.secListComp;
          listCompLen.value = data.listComp.length;
          const isPc = navigatorType();
          if (isPc) {
            window.location.href = `http://www.bncc.com/p_${data.productsVO.classId}/p_${data.productsVO.id}.html`;
          }
          /**
           *  存足迹
           */
          setFootItem({
            type: "product",
            img: (productsVO.value as any).switchPic,
            title: (productsVO.value as any).cnname,
            info: (productsVO.value as any).catno,
            proid: (productsVO.value as any).id,
            brand: (productsVO.value as any).suppliercode
              ? "标准值" + (productsVO.value as any).suppliercode + "组分"
              : (productsVO.value as any).suppliercode,
            specification: (productsVO.value as any).specification,
            concentration: (productsVO.value as any).concentration,
            url: { name: "productdetail", params: { id: route.query.id } },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getData(id.value);
    watch(
      [
        () => {
          return route.query;
        },
        () => {
          return route.params;
        },
      ],
      (to, from) => {
        // 避免登录时触发页面刷新
        console.log(to, from);
        if (to[1].id && !from[1].valid && !from[1].validate) {
          getData(to[1].id as string);
        }
      }
    );

    const loadOption = {
      text: "加载中...",
      color: "#df0024",
      textColor: "#df0024",
    };
    return {
      productsVO,
      list,
      amclass,
      comp,
      id,
      classId,
      isFav,
      RelatedProIdList,
      loadOption,
      secListComp,
      listCompLen,
    };
  },
});
