<template>
  <div v-if="productsVO">
    <div class="product-wrap">
      <div class="product-pic">
        <video
          v-if="!productsVO.pic"
          src="http://1256414354.vod2.myqcloud.com/c8e6626avodtransgzp1256414354/f9f94d555285890814547790536/v.f40.mp4"
          poster="@/assets/img/product-poster.jpg"
          playsinline
          webkit-playinline
          x5-playinline
          x-webkit-airplay="true" webkit-playsinline="true"
          controls
        ></video>
        <img v-else :src="productsVO.pic" />
      </div>
      <div class="product-info">
        <div class="product-name">{{ productsVO.cnname }}</div>
      </div>
      <div class="product-detail">
        <div class="title">商品详情</div>
        <div class="content">
          <table border="1" cellspacing="0" cellpadding="10.013px">
            <thead>
              <tr>
                <th colspan="2">基本信息</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="name">产品编号</td>
                <td>{{ productsVO.catno }}</td>
              </tr>
              <tr>
                <td class="name">中文名称</td>
                <td>{{ productsVO.cnname }}</td>
              </tr>

              <tr v-if="productsVO.enname">
                <td class="name">英文名称</td>
                <td>{{ productsVO.enname }}</td>
              </tr>

              <tr v-if="productsVO.casno">
                <td class="name">CAS</td>
                <td>{{ productsVO.casno }}</td>
              </tr>

              <tr v-if="productsVO.specification">
                <td class="name">规格</td>
                <td>{{ productsVO.specification }}</td>
              </tr>

              <tr v-if="productsVO.purity">
                <td class="name">纯度</td>
                <td>{{ productsVO.purity }}</td>
              </tr>

              <tr v-if="productsVO.nongdu">
                <td class="name">浓度</td>
                <td>{{ productsVO.nongdu }}</td>
              </tr>

              <tr v-if="productsVO.batch">
                <td class="name">批次</td>
                <td>{{ productsVO.batch }}</td>
              </tr>

              <tr v-if="productsVO.statu">
                <td class="name">形态</td>
                <td>{{ productsVO.statu }}</td>
              </tr>

              <tr v-if="productsVO.stroma">
                <td class="name">基质</td>
                <td>{{ productsVO.stroma }}</td>
              </tr>

              <tr v-if="productsVO.shelf_life">
                <td class="name">有效期</td>
                <td>{{ productsVO.shelf_life }}</td>
              </tr>

              <tr v-if="productsVO.storage_condition">
                <td class="name">存储条件</td>
                <td>{{ productsVO.storage_condition }}</td>
              </tr>

              <tr v-if="productsVO.applications">
                <td class="name">用途</td>
                <td>{{ productsVO.applications }}</td>
              </tr>

              <tr v-if="productsVO.notice">
                <td class="name">注意事项</td>
                <td>{{ productsVO.notice }}</td>
              </tr>

              <tr v-if="productsVO.reference">
                <td class="name">参考资料</td>
                <td>{{ productsVO.reference }}</td>
              </tr>

              <tr v-if="productsVO.alias">
                <td class="name">别名</td>
                <td>{{ productsVO.alias }}</td>
              </tr>

              <tr v-if="productsVO.physicochemicalProperty">
                <td class="name">理化性质</td>
                <td>{{ productsVO.physicochemicalProperty }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="title title-top">量值信息</div>
        <div class="content">
          <table border="1" cellspacing="0" cellpadding="10.013px">
            <thead style="background-color: #5f5f5f; border-color: #5f5f5f">
              <tr>
                <th colspan="3">量值信息</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="name">组分</td>
                <td>标准值</td>
                <td>不确定度</td>
              </tr>
              <tr v-for="item in list" :key="item">
                <td class="name">{{ item.components }}</td>
                <td>{{ item.concentration }}</td>
                <td>{{ item.uncertainty }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="other-view" v-if="productsVO.suppliercode == 1">
        <div class="title">产品详情：</div>
        <div class="content">
          <div class="detail-title">一、样品制备</div>
          <div class="detail-content" v-if="productsVO.preparation">
            {{ productsVO.preparation }}
          </div>
          <template
            v-if="
              productsVO.traceability &&
              productsVO.traceability.indexOf('稀释后方可使用') != -1
            "
          >
            <div class="detail-title">二、稀释方法</div>
            <div class="detail-content">{{ productsVO.traceability }}}</div>
            <div class="detail-title">三、稀释后特性量值及扩展不确定度</div>
          </template>
          <template v-else>
            <div class="detail-title">二、溯源性及定值方法</div>
            <div class="detail-content">{{ productsVO.traceability }}</div>
            <div class="detail-title">三、特征量值及扩展不确定度</div>
          </template>
          <table border="1" cellspacing="0" cellpadding="10.013px" v-if="comp">
            <thead>
              <tr>
                <th class="detail-name">组分</th>
                <th>标准值</th>
                <th>不确定度</th>
                <th class="detail-name" v-if="productsVO.stroma">基体</th>
                <th class="detail-name" v-else>编号</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="detail-name">{{ comp.components }}</td>
                <td>{{ comp.concentration }}</td>
                <td>{{ comp.uncertainty }}</td>
                <td align="center" class="detail-name" :rowspan="listCompLen">
                  <span v-if="productsVO.stroma">{{ productsVO.stroma }}</span>
                  <span v-else>{{ productsVO.catno }}</span>
                </td>
              </tr>
              <tr v-for="item in secListComp" :key="item">
                <td class="detail-name">{{ item.components }}</td>
                <td>{{ item.concentration }}</td>
                <td>{{ item.uncertainty }}</td>
              </tr>
            </tbody>
          </table>
          <div class="detail-content">
            <span v-if="productsVO.uncertaintySource"
              >标准值的扩展不确定度{{ productsVO.uncertaintySource }}</span
            >
            <span v-else
              >标准值的扩展不确定度主要由原料纯度、定容等引入的不确定度合成。</span
            >
          </div>
          <div class="detail-title">四、均匀性检验及稳定性考察</div>
          <div class="detail-content">
            <span v-if="amclass"
              >根据国家《一级标准物质》技术规范，对分装后的样品进行随机抽样，采用{{
                amclass.amclass
              }}对该标准物质进行均匀性检验和长期稳定性跟踪考察。结果表明：均匀性符合F检验规则，稳定性考察良好。</span
            >
            本标准物质量值自定值之日起，有效期{{
              productsVO.shelfLife
            }}月,研制单位将继续跟踪监测该标准物质的稳定性，有效期内如发现量值变化，将及时通知用户。
          </div>
          <div class="detail-title">五、包装、储存及使用</div>
          <div class="detail-content">
            包装:本标准物质采用{{ productsVO.packing }}包装,规格{{
              productsVO.specification
            }}携带或运输时应有防碎裂保护。 储存及使用:{{
              productsVO.storageCondition
            }}
          </div>
          <div class="pdt_detail_box">
            <p class="pdt_detail_title">声明</p>
            <p>
              1.
              本标准物质仅供实验室研究与分析测试工作使用。因用户使用或储存不当所引起的投诉，不予承担责任。
            </p>
            <p>
              2.
              收到后请立即核对品种、数量和包装，相关赔偿只限于标准物质本身，不涉及其他任何损失。
            </p>
            <p>
              3.
              仅对加盖“北京北方北纳生物技术研究院标准物质专用章”的完整证书负责。请妥善保管此证书。
            </p>
            <p style>4. 如需获得更多与应用有关的信息，请与技术咨询部门联系。</p>
            <div class="footer-info">
              <div class="info-left">
                <img src="@/assets/BarCode.png" alt />
              </div>
              <div class="info-right">
                <p>北京北方北纳生物技术研究院</p>
                <p>地址：北京市朝阳区汤立路218号</p>
                <p>技术咨询：400-999-3855</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="recommend-title">相关产品：</div>
      <div class="recommend">
        <div class="content">
          <ul>
            <li v-for="item in RelatedProIdList" :key="item">
              <router-link
                :to="{ name: 'productdetail', params: { id: item.id } }"
                push
                >{{ item.cnname }}</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <slot-footer :pid="id" tid="0" type="1" :isFav="isFav"></slot-footer>
  </div>
  <loading :options="loadOption" v-else></loading>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, watch, inject, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import api from "@/api/axios";
import useFootPrint from "@/hooks/useFootPrint";
import SlotFooter from "@/components/common/SlotFooter.vue";
import Loading from "@/components/UI/Loading";
import navigatorType from "@/utils/browser";

export default defineComponent({
  name: "productdetail",
  components: {
    SlotFooter,
    Loading,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const id = ref(route.params.id);
    const classId = ref(route.params.classId);
    const isFav = ref(false);
    const productsVO = ref(null);
    const list = ref([]);
    const secListComp = ref([]);
    const amclass = ref(null);
    const comp = ref({});
    const RelatedProIdList = ref([]);
    const { setFootItem } = useFootPrint();
    const listCompLen = ref(0);
    function getData(id: any) {
      productsVO.value = null;
      api
        .get("/M/Product/ProductDetail/" + id)
        .then((res): void => {
          const data = res.data.obj;
          productsVO.value = data.productsVO;
          document.title = data.productsVO.cnname + "-北纳生物";
          list.value = data.listComp;
          comp.value = data.comp;
          isFav.value = data.isFav;
          amclass.value = data.amclass;
          RelatedProIdList.value = data.RelatedProIdList;
          secListComp.value = data.secListComp;
          listCompLen.value = data.listComp.length;
          const isPc = navigatorType();
          if (isPc) {
            window.location.href = `http://www.bncc.com/p_${data.productsVO.classId}/p_${data.productsVO.id}.html`;
          }
          /**
           *  存足迹
           */
          setFootItem({
            type: "product",
            img: (productsVO.value as any).switchPic,
            title: (productsVO.value as any).cnname,
            info: (productsVO.value as any).catno,
            proid: (productsVO.value as any).id,
            brand: (productsVO.value as any).suppliercode
              ? "标准值" + (productsVO.value as any).suppliercode + "组分"
              : (productsVO.value as any).suppliercode,
            specification: (productsVO.value as any).specification,
            concentration: (productsVO.value as any).concentration,
            url: { name: "productdetail", params: { id: route.query.id } },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getData(id.value);
    watch(
      [
        () => {
          return route.query;
        },
        () => {
          return route.params;
        },
      ],
      (to, from) => {
        // 避免登录时触发页面刷新
        console.log(to, from);
        if (to[1].id && !from[1].valid && !from[1].validate) {
          getData(to[1].id as string);
        }
      }
    );

    const loadOption = {
      text: "加载中...",
      color: "#df0024",
      textColor: "#df0024",
    };
    return {
      productsVO,
      list,
      amclass,
      comp,
      id,
      classId,
      isFav,
      RelatedProIdList,
      loadOption,
      secListComp,
      listCompLen,
    };
  },
});
</script>


<style lang="scss" scoped>
video {
  width: 100%;
}
.product-wrap {
  padding-bottom: 50px;
}
.title-top {
  margin-top: 40px;
}
.product-pic img {
  width: 375px;
  height: 375px;
  display: block;
}

.product-info {
  position: relative;
  padding: 10.013px;
  padding-top: 21.487px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 6px solid #f4f4f4;
}

.product-name {
  width: 255px;
  color: #444444;
  font-size: 16.012px;
  position: relative;
  font-weight: bold;
}

.operation {
  position: absolute;
  right: 10.013px;
  top: 50%;
  transform: translateY(-50%);
}
.operation a {
  color: #cc3333;
  font-size: 15px;
  font-weight: 700;
}

.product-detail .title,
.recommend-title {
  padding-left: 19.988px;
  font-size: 15px;
  color: #444;
  position: relative;
  width: 375px;
  box-sizing: border-box;
  height: 43.988px;
  line-height: 43.988px;
  border-bottom: 1px solid #f4f4f4;
}

.recommend-title {
  margin-top: 0.5rem;
}
.product-detail .title::after,
.recommend-title::after {
  content: "";
  position: absolute;
  width: 2px;
  left: 10.013px;
  top: 50%;
  height: 13.012px;
  background-color: #df0024;
  margin-top: -6.487px;
}

table {
  width: 355.013px;
  border: none;
  margin: 0 auto;
  margin-top: 19.988px;
  border-collapse: collapse;
  border-color: #ccc;
}
thead {
  width: 355.013px;
  background-color: #df0024;
  border: 1px solid #df0024;
}
th {
  color: #fff;
  text-align: left;
  height: 30px;
  box-sizing: border-box;
  padding: 0 9px;
  line-height: 30px;
  font-size: 13.988px;
}
td {
  font-size: 13.988px;
  color: #666666;
  padding: 10.013px;
  word-break: break-word;
}

.name {
  width: 117px;
  box-sizing: border-box;
  border-right-color: #e5e5e5;
  background-color: #f2f2f2;
  line-height: 25.013px;
}
.detail-name {
  width: 49.987px;
  box-sizing: border-box;
}
.other-view {
  margin-top: 19.988px;
  border-top: 1px solid #eee;
}
.other-view .title {
  text-align: justify;
  font-size: 16.012px;
  color: #666666;
  line-height: 25.013px;
  margin: 19.988px 10.013px;
  font-weight: bold;
}

.other-view .content .detail-title {
  width: 355.013px;
  margin: 0 auto;
  font-size: 16.012px;
  color: #444;
  position: relative;

  margin-top: 19.988px;
  box-sizing: border-box;
}

.other-view .content .detail-content {
  width: 355.013px;
  margin: 0 auto;
  font-size: 15px;
  color: #444;
  position: relative;

  box-sizing: border-box;
}

.pdt_detail_box {
  font-size: 16.012px;

  color: #444;
  width: 375px;
  box-sizing: border-box;
  width: 355.013px;
  margin: 0 auto;
}

.info-left {
  margin: 0 auto;
  text-align: center;
}

.productViews {
  width: 109.987px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background-color: #df0024;
  color: #fff;
  border-radius: 36px;
  margin: 0 auto;
  margin-top: 7.987px;
}

.other-view .content a {
  text-align: justify;
  font-size: 16.012px;
  line-height: 25.013px;
  display: block;
  color: #666666 !important;
  margin: 12.488px 10.013px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.recommend {
  width: 355.013px;
  text-align: left;
  margin: 0 auto;

  box-sizing: border-box;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
  position: relative;
}

.recommend .title {
  padding-left: 0.53301rem;
  font-size: 0.4rem;
  color: #444;
  position: relative;
  box-sizing: border-box;
  height: 1.17301rem;
  line-height: 1.17301rem;
  border-bottom: 0.02667rem solid #f4f4f4;
  margin-top: 0.5rem;
}
.recommend .title::after {
  content: "";
  position: absolute;
  width: 0.05333rem;
  left: 0.26701rem;
  top: 50%;
  height: 0.34699rem;
  background-color: #df0024;
  margin-top: -0.17299rem;
}
.recommend li {
  position: relative;
}

.recommend li::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 10.013px;
  width: 4.988px;
  height: 4.988px;
  background: #7f7f7f;
  border-radius: 50%;
}

.recommend a {
  text-align: justify;
  font-size: 13.988px;
  line-height: 25.013px;
  display: block;
  color: #666666;
  margin: 10.013px 0;
  overflow: hidden;
  padding-left: 10.013px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.recommend h3 {
  margin: 0;
  font-weight: normal;
}

.recommend-title {
  padding-left: 19.988px;
  font-size: 15px;
  color: #444;
  position: relative;
  width: 375px;
  box-sizing: border-box;
  height: 43.988px;
  line-height: 43.988px;
  border-bottom: 1px solid #f4f4f4;
}

.recommend-title {
  margin-top: 0.5rem;
}
.recommend-title::after {
  content: "";
  position: absolute;
  width: 2px;
  left: 10.013px;
  top: 50%;
  height: 13.012px;
  background-color: #df0024;
  margin-top: -6.487px;
}
</style>
